<template>
    <!-- 新闻资讯 -->
    <div class="phone_0">
        <div class="computer">
            <div class="information">
                <div class="box">
                    <div class="title small_title">{{node.newsTranslate}}</div>
                    <div class="information_main">
                        <div class="information_menu">
                            <el-menu
                                default-active="1"
                                class="el-menu-vertical-demo"
                                @select="handleOpen"
                                style="border-right: 0">
                                <el-menu-item :index="item.id" v-for="(item, index) in newsCategory" :key="index">
                                    <!-- <i class="el-icon-s-flag"></i> -->
                                    <span slot="title">{{item.name}}</span>
                                </el-menu-item>
                            </el-menu>
                        </div>
                        <div class="information_details" v-loading="infoLoading">
                            <el-row :gutter="20">
                                <el-col v-for="(item, index) in detailsList" :key="index" :span="12">
                                    <div class="details">
                                        <img :src="item.url" :class="{'cp': item.href}" @click="newsDetail(item)" :alt="item.title">
                                        <div>
                                            <div class="title line_clamp small_title" @click="newsDetail(item)" :title="item.title">{{item.title}}</div>
                                            <div class="details_text main_body">{{item.text}}</div>
                                            <div class="details_date main_body">{{node.releaseTimeTranslate}}: {{item.date}}</div>
                                        </div>
                                    </div>
                                </el-col>
                            </el-row>
                            <el-pagination @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[5, 10, 15, 20]" :page-size="10" background style="margin-top: 20px" layout="prev, pager, next" :total="total"></el-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="phone">
            <ul class="tab-head">
                <li :class="['tab-head-item', navIndex == index + 1 ? 'active' : '' ]" v-for="(item, index) in newsCategory" :key="index" @click="navOpen(item)">{{item.name}}</li>
            </ul>
            <div class="box">
                <el-row :gutter="20">
                    <el-col v-for="(item, index) in detailsList" :key="index" :span="24">
                        <div class="p_details">
                            <img :src="item.url" :class="{'cp': item.href}" @click="newsDetail(item)" :alt="item.title">
                            <div>
                                <div class="title line_clamp small_title" @click="newsDetail(item)" :title="item.title">{{item.title}}</div>
                                <div class="details_text main_body">{{item.text}}</div>
                                <div class="details_date main_body">{{node.releaseTimeTranslate}}: {{item.date}}</div>
                            </div>
                        </div>
                    </el-col>
                </el-row>
                <el-pagination @current-change="handleCurrentChange" :pager-count="5" :current-page="currentPage" :page-sizes="[5, 10, 15, 20]" :page-size="10" background layout="prev, pager, next" :total="total"></el-pagination>
            </div>
        </div>
        <phone-menu></phone-menu>
    </div>
</template>

<script>
import phoneMenu from '../../components/phoneMenu.vue'
import { sitenewsList } from "@/api/index"
export default {
    data () {
        return {
            detailsList: [],
            currentPage: 1,
            total: 0,
            menuHeight: 0,
            businessCategory: 1,
            navIndex: '1',
            infoLoading: false
        }
    },
    components: {
        phoneMenu   
    },
    computed: {
        newsCategory(){
            return this.$store.state.newsCategory
        },
        node(){
            return this.$store.state.node
        },
        siteCode(){
            return this.$store.state.siteCode
        },
    },
    mounted () {
        this.menuHeight = document.getElementsByClassName('information_details')[0].offsetHeight
        let maxResult = window.matchMedia('(max-width: 980px)');
        if (maxResult.matches) {
            var h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
            let f = document.getElementsByClassName('phone_footer')[0].offsetHeight
            let he = document.getElementsByClassName('p_header')[0].offsetHeight
            document.getElementsByClassName('phone_0')[0].style.minHeight = (h - f - he - 2) + 'px'
        }
        this.getList()
    },
    methods: {
        getList() {
            let params = {
                current: this.currentPage,
                size: 10,
                asc: false,
                orderByField: 'id desc',
                querydata: {
                    businessCategory: this.businessCategory,
                    siteCode: this.$store.state.siteCode,
                }
            }
            params.querydata = JSON.stringify(params.querydata)
            this.infoLoading = true;
            sitenewsList(params).then((res) => {
                if(res && res.code == 0) {
                    this.total = res.data.total
                    this.detailsList = []
                    res.data.records.map(item => {
                        this.detailsList.push({
                            id: item.id,
                            title: item.title,
                            text: item.bizSynopsis,
                            date: item.createTime.slice(0, 11),
                            url: this.imgTransition(JSON.parse(item.imgs)[0].urlName),
                            href: JSON.parse(item.imgs)[0].href,
                        })
                    })
                    this.infoLoading = false
                }
            }).catch(err => {
                this.infoLoading = false
                console.log('err', err);
            })
        },
        handleOpen(key, keyPath) {
            this.businessCategory = key
            this.getList()
        },
        handleCurrentChange (val) {
            this.currentPage = val
            this.getList()
        },
        // 新闻详情
        newsDetail(data) {
            window.open('#/newsDetail?id=' + data.id + '&siteCode=' + this.siteCode)
        },
        navOpen(data) {
            this.navIndex = data.id
            this.businessCategory = data.id
            this.getList()
        },
        // 图片路径转换
        imgTransition(str) {
            return this.$store.state.domainImg + str.slice(0, 1) + '/' + str.slice(1, 2) + '/' + str
        },
    }
}
</script>

<style lang="scss" scoped>
::v-deep .el-menu {
    border-right: solid 1px rgb(84, 92, 100);
}
::v-deep .el-carousel__indicators--horizontal {
    bottom: 40px;
}
.tab-head{
    height: 10vw;
    margin-bottom: 4vw;
    line-height: 10vw;
    list-style-type: none;
    display:-webkit-box;
    display:-webkit-flex;
    display:-ms-flexbox;
    display:flex;
    -webkit-flex-wrap:nowrap;
    -ms-flex-wrap:nowrap;
    flex-wrap:nowrap;
    -webkit-box-pack:justify;
    -webkit-justify-content:space-between;
    -ms-flex-pack:justify;
    justify-content:space-between;
    padding:0;
    overflow:auto;
    border-bottom: 1px solid #ccc;
}
.tab-head-item{
    -webkit-box-flex:1;
    -webkit-flex:1 0 auto;
    -ms-flex:1 0 auto;
    flex:1 0 auto;
    color:#303133;
    padding:0 6vw;
    background-color: #fff;
}
.active {
    color: #409eff;
    background-color: #ecf5ff;
}
.phone {
    ::v-deep .el-pagination {
        margin-bottom: 2vw;
        button {
            height: 8vw;
            padding: 0 2vw;
            i {
                font-size: 4vw;
            }
        }
        li {
            height: 8vw;
            line-height: 8vw;
            min-width: 8vw;
            font-size: 4vw;
        }
    }
}
.p_details {
    width: 100%;
    margin-bottom: 4vw;
    padding-bottom: 4vw;
    border-bottom: 1px dashed #dedede;
    img {
        width: 100%;
        height: 50vw;
    }
    .title{
        font-size: 3.5vw;
        font-weight: 800 !important;
        margin: 2vw 0;
    }
    .details_text {
        font-size: 3vw;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        overflow: hidden;
        margin-bottom: 2vw;
        color: #666;
    }
    .details_date {
        font-size: 2vw;
        color: #666;
    }
}
</style>